import 'isomorphic-fetch';
import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import { messagingLanguage } from './login.data';

export const ATTEMPT_REGISTER_REQUEST = 'ATTEMPT_REGISTER_REQUEST';
export const ATTEMPT_REGISTER_SUCCESS = 'ATTEMPT_REGISTER_SUCCESS';
export const ATTEMPT_REGISTER_FAILURE = 'ATTEMPT_REGISTER_FAILURE';

export const attemptRegisterRequest = createAction(ATTEMPT_REGISTER_REQUEST);
export const attemptRegisterSuccess = createAction(ATTEMPT_REGISTER_SUCCESS);
export const attemptRegisterFailure = createAction(ATTEMPT_REGISTER_FAILURE);

export const attemptRegister = (data = {}, checkout = false) => (
  (dispatch) => {
    const url = checkout ? '/api/registration?checkout=true' : '/api/registration';
    dispatch(attemptRegisterRequest())
    return fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((data) => {
        return Promise.all([data, data.json()]);
      })
      .then(([response, json]) => {
        if (response.ok) {
          dispatch(
            attemptRegisterSuccess({ message: 'Registration successful' })
          );
        }
        else {
          throw Error(json.statusText);
        }
      })
      .catch((error) => {
        console.log(error);

        // Check our messaging transformer for the errors: If it doesn't exist in our
        // object, just give them a generic error.
        const prettyError = _get(messagingLanguage, `en-us.${error}`, 'Please try again later.');
        dispatch(attemptRegisterFailure({ message: `${prettyError}`}));
      })
  }
);
