import Immutable from 'immutable';

import {
  CLEAR_ERRORS
} from '../actions/clearErrors';

import {
  ATTEMPT_REGISTER_REQUEST,
  ATTEMPT_REGISTER_SUCCESS,
  ATTEMPT_REGISTER_FAILURE,
 } from '../actions/register';

const initialState = Immutable.fromJS({
  loading: false,
  registered: false,
  message: '',
});

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTEMPT_REGISTER_REQUEST:
      return state
        .set('loading', true)
        .set('message', '');
    case ATTEMPT_REGISTER_SUCCESS:
      return state
        .set('message', action.payload.message)
        .set('registered', true)
        .set('loading', false);
    case ATTEMPT_REGISTER_FAILURE:
      return state
        .set('message', action.payload.message)
        .set('loading', false);

    case CLEAR_ERRORS:
      return state
        .set('message', '');

    default:
      return state;
  }
}

export default registerReducer;
