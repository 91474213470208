import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EuiButton } from '@elastic/eui/lib/components/button/button';
import { EuiCallOut } from '@elastic/eui/lib/components/call_out';
import { EuiFieldPassword } from '@elastic/eui/lib/components/form/field_password';
import { EuiFieldText } from '@elastic/eui/lib/components/form/field_text';
import { EuiForm } from '@elastic/eui/lib/components/form/form';
import { EuiFormRow } from '@elastic/eui/lib/components/form/form_row';

import { attemptLogin } from '../../actions/login';
import { attemptRegister } from '../../actions/register';
import { checkForSession } from '../../actions/session';
import { clearErrors } from '../../actions/clearErrors';

export class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);

    // `destination` is the informal name of the app the user is trying to
    // access (/register/$destination/). It must match up to its object key within
    // the `../actions/login.data.js` file for it to be of any use.
    const destination = _get(props.match, 'params.destination', null);

    this.state = {
      destination,
      email: '',
      firstName: '',
      lastName: '',
      password1: '',
      password2: '',
      fieldErrors: {
        email: false,
        firstName: false,
        lastName: false,
        password1: false,
        password2: false
      },
      redirect: false,
      redirectURL: null,
    }

    // Bind function(s)
    this.handleRegister = this.handleRegister.bind(this);
  }

  // If the user has an active session, this will flip the `redirect` prop
  // to true and send them on their way to whichever app they're trying to
  // register for
  componentDidMount() {
    this.props.checkForSession(this.state.destination);
  }

  componentDidUpdate(prevProps) {
    // If the user just registered successfully and they were in the middle of the
    // checkout process, let's just log them in (with the password still sitting in
    // the password field)
    if (
      !prevProps.registered &&
      this.props.registered &&
      this.state.destination === 'checkout'
    ) {
      this.props.attemptLogin({
        username: this.state.email,
        password: this.state.password1
      }, 'checkout');
    }

    // If we receive new props telling us to redirect, let's setState() with that
    // data, so the render() method can pick it up and do good things with it
    if (!prevProps.redirect && this.props.redirect) {
      // A barebones find-and-replace function. Can be expanded upon if needbe.
      const replacedURL = this.props.redirectURL.replace('__TOKEN__', this.props.token);
      this.setState({ redirectURL: replacedURL });
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  handleRegister(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.attemptRegister({
        profile: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          login: this.state.email
        },
        credentials: {
          password: {
            value: this.state.password1
          }
        }
      }, this.state.destination === 'checkout');
    }
  }

  onFieldChange = event => {
    let target = event.target.name;

    // These lines ensure that, when a user begins changing a field currently
    // in an error state, we remove said error state
    const fieldErrors = this.state.fieldErrors;
    fieldErrors[target] = false;
    this.setState({
      fieldErrors: fieldErrors,
    });

    // Additionally, sync component state's value with the field value
    this.setState({
      [target]: event.target.value
    });
  }

  validateForm() {
    let formIsInvalid = false;
    const fieldErrors = {};

    // Simple, trustworthy front end validation
    if (_isEmpty(this.state.firstName)) {
      formIsInvalid = true;
      fieldErrors['firstName'] = 'First name is required';
    }
    if (_isEmpty(this.state.lastName)) {
      formIsInvalid = true;
      fieldErrors['lastName'] = 'Last name is required';
    }
    if (_isEmpty(this.state.email)) {
      formIsInvalid = true;
      fieldErrors['email'] = 'Email is required';
    }
    if (_isEmpty(this.state.password1)) {
      formIsInvalid = true;
      fieldErrors['password1'] = 'Password is required';
    }
    if (_isEmpty(this.state.password2)) {
      formIsInvalid = true;
      fieldErrors['password2'] = 'Retype your password';
    }
    if (this.state.password1 !== this.state.password2) {
      formIsInvalid = true;
      fieldErrors['password2'] = 'Password does not match';
    }
    if (this.state.password1.length < 8) {
      formIsInvalid = true;
      fieldErrors['password1'] = 'Password must be 8+ characters long';
    }

    if (formIsInvalid) {
      this.setState({ fieldErrors: fieldErrors });
      return false;
    }

    return true;
  }

  render() {
    const isLoading = !!this.props.loading || !!this.state.redirectURL;

    if (this.state.redirectURL) {
      window.location = this.state.redirectURL;
    }

    if (this.props.loginError && !isLoading) {
      return (
        <EuiCallOut
          color="warning"
          title="Error"
          iconType="alert"
        >
          <p>
            Your account has been created, but we had trouble connecting to your shopping cart. Please try <Link to="/login/checkout">logging in</Link> with your new account.
          </p>
        </EuiCallOut>
      )
    }

    // For new registrants who were not in the checkout flow, there's nowhere to send
    // them before they've verified their account
    if (this.props.registered && !isLoading) {
      return (
        <EuiCallOut title="Success">
          <p>
            Account created! Please check your email for verification instructions.
          </p>
        </EuiCallOut>
      )
    }
    else {
      return (
        <React.Fragment>
          { this.props.errorMessage && !isLoading &&
              <React.Fragment>
                <EuiCallOut
                  title="Registration error"
                  color="warning"
                  iconType="alert"
                >
                  <p>{ `${this.props.errorMessage}` }</p>
                </EuiCallOut>
                <div className={classNames('euiSpacer', 'euiSpacer--m')}></div>
              </React.Fragment>
          }
          <EuiForm>
            <form onSubmit={this.handleRegister}>
              <EuiFormRow
                error={this.state.fieldErrors.firstName || undefined}
                isInvalid={!!this.state.fieldErrors.firstName}
                label="First Name">
                <EuiFieldText
                  autoFocus
                  name="firstName"
                  onChange={this.onFieldChange}
                  placeholder="Enter first name"
                />
              </EuiFormRow>
              <EuiFormRow
                error={this.state.fieldErrors.lastName || undefined}
                isInvalid={!!this.state.fieldErrors.lastName}
                label="Last Name">
                <EuiFieldText
                  name="lastName"
                  onChange={this.onFieldChange}
                  placeholder="Enter last name"
                />
              </EuiFormRow>
              <EuiFormRow
                error={this.state.fieldErrors.email || undefined}
                isInvalid={!!this.state.fieldErrors.email}
                label="Email">
                <EuiFieldText
                  name="email"
                  onChange={this.onFieldChange}
                  placeholder="Enter email address"
                  type="email"
                />
              </EuiFormRow>
              <EuiFormRow
                error={this.state.fieldErrors.password1 || undefined}
                isInvalid={!!this.state.fieldErrors.password1}
                label="Password"
              >
                <EuiFieldPassword
                  name="password1"
                  onChange={this.onFieldChange}
                  placeholder="Enter password"
                />
              </EuiFormRow>
              <EuiFormRow
                error={this.state.fieldErrors.password2 || undefined}
                isInvalid={!!this.state.fieldErrors.password2}
                label="Password Confirmation"
              >
                <EuiFieldPassword
                  name="password2"
                  onChange={this.onFieldChange}
                  placeholder="Enter password again"
                />
              </EuiFormRow>
              <br />
              <EuiButton
                fill
                isLoading={isLoading}
                type="submit"
              >
                Create Account
              </EuiButton>
            </form>
          </EuiForm>
          <br />
          <div className={classNames('euiText', 'euiText--small')}>
            Already have an account? <Link to={`/login/${this.state.destination}`}>Login</Link>
          </div>
          <hr className={classNames('euiHorizontalRule', 'euiHorizontalRule--marginLarge')} />
          <div className={classNames('euiText', 'euiText--extraSmall')}>
            <p>By submitting you agree to the <a href="https://www.elastic.co/agreements/inc/cloud-services-standard" rel="nofollow" target="_new">Elastic Cloud Standard Terms of Service</a> and to receive occasional emails from Elastic. Your personal data will be processed in accordance with Elastic’s <a href="https://www.elastic.co/legal/privacy-statement" rel="nofollow" target="_new">privacy statement</a>.</p>
          </div>
        </React.Fragment>
      )
    }
  }
}

RegisterContainer.propTypes = {
  attemptRegister: PropTypes.func,
  checkForSession: PropTypes.func,
  clearErrors: PropTypes.func,
  registered: PropTypes.bool,
  errorMessage: PropTypes.string,
  loginError: PropTypes.string,
  match: PropTypes.object,
  redirect: PropTypes.bool,
  redirectURL: PropTypes.string,
  token: PropTypes.string,
};

RegisterContainer.defaultProps = {
  attemptRegister: () => {},
  checkForSession: () => {},
  clearErrors: () => {},
  registered: false,
  errorMessage: "Store not loaded",
  loginError: null,
  match: {},
  redirect: false,
  redirectURL: null,
  token: null,
};

const mapStateToProps = state => ({
  loading: state.register.get('loading') || state.login.get('loading'),
  registered: state.register.get('registered'),
  errorMessage: state.register.get('message'),
  loginError: state.login.get('error'), // If there's a problem with the netsuite user creation
  redirect: state.login.get('redirect'),
  redirectURL: state.login.get('redirectURL'),
  token: state.login.get('token'),
});

const mapDispatchToProps = dispatch => ({
  attemptLogin: (data, destination) => { dispatch(attemptLogin(data, destination)) },
  attemptRegister: (data, checkout) => { dispatch(attemptRegister(data, checkout)) },
  checkForSession: (destination) => { dispatch(checkForSession(destination)) },
  clearErrors: () => { dispatch(clearErrors()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
