/* Import order for components */
// 1. React, redux, and other boilerplate libraries & tools
import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import classNames from 'classnames';

// 2. External components [i.e., @elastic/eui] (alphabetized)

// 3. Internal components (alphabetized)
import AccessDenied from './components/AccessDenied';
import ApiTestButton from './containers/ApiTestButton';
import LoginContainer from './containers/LoginContainer';
import PageTitle from './components/PageTitle';
import RegisterContainer from './containers/RegisterContainer';
import RequestResetPasswordContainer from './containers/RequestResetPasswordContainer';
import ValidateTokenContainer from './containers/ValidateTokenContainer';

// 4. Side-effect-only imports [usually CSS/img files]
import '@elastic/eui/dist/eui_theme_light.css';
import './App.scss';
import '../public/img/elastic-cluster--full-color.png';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="euiPage">
          <div className="euiPageBody">
            <div className={classNames(
              'euiPageContent',
              'euiPageContent--verticalCenter',
              'app--main',
            )}>
              <header className={classNames(
                'euiPageContentHeader',
                'euiPageContentHeader--responsive'
              )}>
                <img
                  src="/img/elastic-cluster--full-color.png"
                  alt="Elastic logo"
                  className="elastic-logo"
                />
              </header>

              <PageTitle />

              <main className={classNames(
                'euiPageContentBody',
                'euiPanel',
                'euiPanel--paddingLarge',
              )}>
                <Route path="/access-denied" exact component={AccessDenied} />
                <Route path="/test-local-api" exact component={ApiTestButton} />
                <Route path="/reset-password" exact component={RequestResetPasswordContainer} />
                <Route path="/reset-password/:token" exact component={ValidateTokenContainer} />
                <Route path="/activate/:token" exact render={(props) => <ValidateTokenContainer {...props} activation={true} />} />
                <Route path="/login/:destination" component={LoginContainer} />
                <Route path="/register" exact component={RegisterContainer} />
                <Route path="/register/:destination" exact component={RegisterContainer} />
                <Route path="/login" exact render={() => <Redirect to="/login/partner" />} />
                <Route path="/" exact render={() => <Redirect to="/login/partner" />} />
              </main>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
