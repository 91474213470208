import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { titleData } from './title-data';

import './style.scss';

const PageTitle = (props) => {
  // Check if our current location matches the regex from titleData
  const titleRegex = Object.keys(titleData).filter((regex) =>
    props.location.pathname.match(regex)
  );

  return <React.Fragment>{titleData[titleRegex]}</React.Fragment>;
};

PageTitle.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(PageTitle);
